<template>
  <v-container class="px-0">
    <v-row>
      <v-col cols="12" md="9" class="pr-16">
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-img
              class="mt-5 mb-3"
              contain
              max-height="95"
              max-width="105"
              :src="templatePreview"
            />
            <h1 class="title_header">
              {{ template.name }}
            </h1>
          </v-col>

          <v-col cols="9" class="pt-4">
            <h2 class="subtitle_header">
              {{ $t('issue.pageTitle') }}
            </h2>
          </v-col>
          <v-col cols="3" class="pt-4 text-right">
            <v-btn
              v-if="getTemplateName == 'keepCodingV1'"
              :disabled="canIssueCred"
              class="next"
              @click="shownInBulkModal = true"
            >
              <IconAdd />{{ $t('issue.new') }}
            </v-btn>
            <v-btn
              v-else-if="getDesignType == 'Card'"
              :disabled="canIssueCred"
              class="next"
              @click="$router.push({ path: 'IssueCredentials' })"
            >
              <IconAdd />{{ $t('issue.new') }}
            </v-btn>
            <v-menu
              v-else
              bottom
              left
              :offset-y="true"
              class="dot-menu"
              content-class="dot-menu"
            >
              <template v-slot:activator="{ on }">
                <v-btn :disabled="canIssueCred" class="next" v-on="on">
                  <IconAdd />{{ $t('issue.new') }}
                </v-btn>
              </template>

              <v-list :ripple="false" class="dot-menu">
                <v-list-item :ripple="false" :to="{ path: 'IssueCredentials' }">
                  <v-list-item-title class="menu-title">{{
                    $t('issue.menu[0]')
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item :ripple="false" :to="{ path: 'IssueInBulk' }">
                  <v-list-item-title class="menu-title">{{
                    $t('issue.menu[1]')
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="12" class="tables-view">
            <v-tabs :show-arrows="false" fixed-tabs v-model="tab">
              <v-tab href="#tab-1" class="menu_text"
                >{{ $t('viewCredential.tabs[0]') }} ({{
                  allEntriesTable.length
                }})
              </v-tab>
              <v-tab href="#tab-2" class="menu_text"
                >{{ $t('viewCredential.tabs[1]') }} ({{ pendingTable.length }})
              </v-tab>
              <v-tab href="#tab-3" class="menu_text"
                >{{ $t('viewCredential.tabs[2]') }} ({{ approvedTable.length }})
              </v-tab>
              <v-tab href="#tab-4" class="menu_text"
                >{{ $t('viewCredential.tabs[3]') }} ({{ revokedTable.length }})
              </v-tab>
              <v-text-field
                v-model="search"
                class="search-table"
                append-icon="mdi-magnify"
                :label="$t('table.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">
                <DataTable
                  v-if="allEntriesTable.length > 0"
                  @updateTable="updateTable()"
                  :search="search"
                  :headers="credentialsHeaders"
                  :items="allEntriesTable"
                  :tableOptions="hasPendingApproval"
                />

                <v-card v-else class="pa-8 dca-table">
                  <v-img
                    class="mx-auto"
                    max-height="99"
                    max-width="130"
                    :src="emptyTablePhoto"
                  />
                  <v-card-text class="strong_text pa-0 pt-2">
                    <p
                      class="light_text text-center mb-0 mx-auto"
                      style="max-width: 350px"
                    >
                      {{ $t('viewCredential.noCredentials[0]') }}
                      <router-link
                        class="links"
                        style="font-size: 13px"
                        :to="{ path: 'IssueCredentials' }"
                      >
                        {{ $t('viewCredential.noCredentials[1]') }}
                      </router-link>
                      {{ $t('viewCredential.noCredentials[2]') }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <DataTable
                  v-if="pendingTable.length > 0"
                  @updateTable="updateTable()"
                  :search="search"
                  :headers="credentialsHeaders"
                  :items="pendingTable"
                  :tableOptions="hasPendingApproval"
                />

                <v-card v-else class="pa-8 dca-table">
                  <v-img
                    class="mx-auto"
                    max-height="99"
                    max-width="130"
                    :src="emptyTablePhoto"
                  />
                  <v-card-text class="strong_text pa-0 pt-2">
                    <p
                      class="light_text text-center mb-0 mx-auto"
                      style="max-width: 350px"
                    >
                      {{ $t('viewCredential.pending') }}
                      <router-link
                        class="links"
                        style="font-size: 13px"
                        :to="{ path: 'IssueCredentials' }"
                      >
                        {{ $t('viewCredential.noCredentials[1]') }}
                      </router-link>
                      {{ $t('viewCredential.noCredentials[2]') }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-3">
                <DataTable
                  v-if="approvedTable.length > 0"
                  @updateTable="updateTable()"
                  :search="search"
                  :headers="credentialsHeaders"
                  :items="approvedTable"
                />

                <v-card v-else class="pa-8 dca-table">
                  <v-img
                    class="mx-auto"
                    max-height="99"
                    max-width="130"
                    :src="emptyTablePhoto"
                  />
                  <v-card-text class="strong_text pb-0 px-16">
                    <p
                      class="light_text text-center mb-0 mx-auto"
                      style="max-width: 350px"
                    >
                      {{ $t('viewCredential.approved') }}
                      <router-link
                        class="links"
                        style="font-size: 13px"
                        :to="{ path: 'IssueCredentials' }"
                      >
                        {{ $t('viewCredential.noCredentials[1]') }}
                      </router-link>
                      {{ $t('viewCredential.noCredentials[2]') }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-4">
                <DataTable
                  v-if="revokedTable.length > 0"
                  @updateTable="updateTable()"
                  :search="search"
                  :headers="credentialsHeaders"
                  :items="revokedTable"
                />

                <v-card v-else class="pa-8 dca-table">
                  <v-img
                    class="mx-auto"
                    max-height="99"
                    max-width="130"
                    :src="emptyTablePhoto"
                  />
                  <v-card-text class="strong_text pa-0 pt-2">
                    <p
                      class="light_text text-center mb-0 mx-auto"
                      style="max-width: 350px"
                    >
                      {{ $t('viewCredential.revoked') }}
                      <router-link
                        class="links"
                        style="font-size: 13px"
                        :to="{ path: 'IssueCredentials' }"
                      >
                        {{ $t('viewCredential.noCredentials[1]') }}
                      </router-link>
                      {{ $t('viewCredential.noCredentials[2]') }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3" class="pt-14 pb-8 counters">
        <v-row class="pt-16">
          <v-col cols="12" class="pt-16 pb-2 px-0">
            <v-card>
              <v-card-text class="strong_text">
                <IconIssuedCredentials />
                <div>
                  <p>{{ allEntriesTable.length }}</p>
                  <span> {{ $t('dashboard.counters.issued') }} </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="pt-2 pb-2 px-0">
            <v-card>
              <v-card-text class="strong_text">
                <IconRevokedCredentials />
                <div>
                  <p>{{ revokedTable.length }}</p>
                  <span> {{ $t('dashboard.counters.revoked') }} </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="pt-2 pb-2 px-0">
            <v-card>
              <v-card-text class="strong_text">
                <IconApprovedCredentials />
                <div>
                  <p>{{ approvedTable.length }}</p>
                  <span> {{ $t('dashboard.counters.approved') }} </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-col cols="12" class="pt-2 pb-2 px-0">
            <v-card style="opacity:0;" :height="50" @click="sync"> </v-card>
          </v-col> -->
        </v-row>
      </v-col>
    </v-row>

    <!-- Modals -->
    <IssueCertificate
      v-if="issueCertificateModal"
      @close="closeIssueCertificate"
      @success="openSuccessModal"
      :template="template"
      :caName="caName"
    />
    <InBulkModal
      v-if="shownInBulkModal"
      :tid="template.tid"
      :caName="caName"
      :frontend_props="template.frontend_props"
      :credName="template.name"
      :urlPhoto="photoURL"
      :cardHeaders="cardHeaders"
      :excelTemplate="template.excelTemplate"
      @close="closeInBulkModal"
      @success="openSuccessModal"
    />
    <SuccessModal
      v-if="successModal"
      :message="message"
      @close="closeSuccessModal"
    />
  </v-container>
</template>

<script>
import IssueCertificate from '@/modals/IssueCertificate';
import InBulkModal from '@/modals/CredInBulk';

import SuccessModal from '@/modals/SuccessModal';

import DataTable from '@/components/DataTable';

import IconIssuedCredentials from '@/assets/icon-issued-credentials-blue.vue';
import IconRevokedCredentials from '@/assets/icon-revoked-credentials';
import IconApprovedCredentials from '@/assets/icon-approved-credentials';

import IconAdd from '@/assets/icon-add.vue';

import {
  DASHBOARD,
  GET_CA,
  GET_TEMPLATE,
  TEMPLATE_SYNC,
} from '@/store/actions';
import { mapGetters } from 'vuex';

export default {
  name: 'ViewTemplate',
  components: {
    DataTable,
    IssueCertificate,

    IconAdd,
    IconRevokedCredentials,
    IconApprovedCredentials,
    IconIssuedCredentials,
    SuccessModal,
    InBulkModal,
  },
  props: {
    tid: {
      required: true,
    },
  },

  watch: {
    cid(value) {
      if (value) {
        this.getTemplate();
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('template/tid', null);
  },
  computed: {
    ...mapGetters([
      'caName',
      'photoURL',
      'totalIssuedCredentials',
      'maxIssuedCounter',
    ]),
    templatePreview() {
      return (
        this.template &&
        this.template.frontend_props &&
        this.template.frontend_props.preview
      );
    },
    canIssueCred() {
      return this.totalIssuedCredentials >= this.maxIssuedCounter;
    },
    cid() {
      // gets updated automatically
      return this.$store.state.cid;
    },
    getDesignType() {
      return (
        this.template &&
        this.template.frontend_props &&
        this.template.frontend_props.design
      );
    },
    getTemplateName() {
      return (
        this.template &&
        this.template.frontend_props &&
        this.template.frontend_props.customTemplateName
      );
    },
  },
  created() {
    this.debug(`*** ${this.$options.name} ***`);
  },
  mounted() {
    if (this.tid && this.cid) this.getTemplate();
  },
  methods: {
    updateTable() {
      this.$store.dispatch(DASHBOARD).then(() => this.getTemplate());
    },
    sync() {
      this.$store.dispatch(GET_CA, this.tid).then((res) =>
        this.$store
          .dispatch(TEMPLATE_SYNC, res.data.data)
          .then(() => {})
          .catch((err) => {
            console.error(err);
            this.sending = false;
          })
      );
    },
    openSuccessModal(message) {
      this.successModal = true;
      this.issueCertificateModal = false;
      this.message = message;
    },
    closeSuccessModal() {
      this.updateTable();
      this.successModal = false;
    },
    closeInBulkModal() {
      this.updateTable();
      this.shownInBulkModal = false;
    },
    getTemplate() {
      console.log('tid', this.tid);

      this.$store.dispatch(GET_TEMPLATE, { tid: this.tid }).then((template) => {
        if (!template) return;
        this.template = template;
        console.log('template', template);
        this.allEntriesTable = template.users;

        let el = template.templateItens.find((i) => {
          return i.attrFormat == 'table';
        });
        if (el) {
          this.cardHeaders = { headers: el.table_headers };
        }

        this.pendingTable = template.users.filter((u) => {
          this.hasPendingApproval = u.status == this.PENDING_APPROVAL;
          return (
            u.status == this.WAITING_WALLET || u.status == this.PENDING_APPROVAL
          );
        });

        this.approvedTable = template.users.filter((u) => {
          return u.status == this.APPROVED;
        });
        this.revokedTable = template.users.filter((u) => {
          return u.status == this.REVOKED;
        });

        if (template.users.length > 0) {
          console.log(template.users[0].public_field.attr);

          this.credentialsHeaders[this.$i18n.locale][0] = {
            text: template.users[0].public_field.attr,
            align: 'start',
            value: 'public_field.value',
          };
        }
      });
    },
    closeIssueCertificate() {
      this.issueCertificateModal = false;
    },
  },
  data() {
    return {
      tab: 0,
      template: {},
      issueCertificateModal: false,
      shownInBulkModal: false,
      userData: {},
      search: '',
      hasPendingApproval: false,
      allEntriesTable: [],
      pendingTable: [],
      approvedTable: [],
      revokedTable: [],
      cardHeaders: [],
      emptyTablePhoto: require('@/assets/no-pending-tasks.png'),
      message: '',
      successModal: false,
      credentialsHeaders: {
        en: [
          {
            text: 'Public Attr',
            align: 'start',
            value: 'public_field.value',
          },
          { text: 'Credential ID', value: 'id' },
          {
            text: 'Status',
            value: 'status',
            sortable: false,
            filterable: false,
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            filterable: false,
          },
        ],
        pt: [
          {
            text: 'Atributo público',
            align: 'start',
            value: 'public_field.value',
          },
          { text: 'ID de Credencial', value: 'id' },
          {
            text: 'Estado ',
            value: 'status',
            sortable: false,
            filterable: false,
          },
          {
            text: 'Acções',
            value: 'actions',
            sortable: false,
            filterable: false,
          },
        ],
        es: [
          {
            text: 'Atributo público',
            align: 'start',
            value: 'public_field.value',
          },
          { text: 'ID de Credencial', value: 'id' },
          {
            text: 'Estado ',
            value: 'status',
            sortable: false,
            filterable: false,
          },
          {
            text: 'Acciónes',
            value: 'actions',
            sortable: false,
            filterable: false,
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss">
.tables-view.col {
  .v-tabs-items {
    box-shadow: 0 2px 4px 0 var(--very-light-grey);
    margin-top: 35px;
  }

  .v-tabs {
    border-bottom: solid 1px var(--light-grey-blue);
    .v-tabs-bar {
      height: 38px;
      background-color: transparent;
      .v-tabs-slider {
        background-color: #0ea4b5;
      }
    }
    .v-tab {
      margin: 0;
      width: fit-content;
      padding: 0;
    }
  }
  .v-tabs-items {
    background-color: transparent;
  }
}
</style>
