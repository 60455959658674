<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal-wallid"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header">
            {{ $t('issue.title') }}
            <v-spacer />
            <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
            >
              <IconClose />
            </button>
          </slot>
        </header>

        <div class="modal-body scroll">
          <slot name="body">
            <v-container class="">
              <v-row>
                <v-col cols="6" class="pt-0 pl-0">
                  <v-stepper class="stepper-signup" v-model="step">
                    <v-stepper-items>
                      <v-stepper-content step="0" class="pa-0">
                        <v-container class="">
                          <v-row>
                            <v-col cols="12" class="">
                              <p class="mb-0 strong_text">
                                {{ $t('createCertModal.front') }}
                              </p>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              v-for="(field, index) in templateValues"
                              :key="field._id"
                              cols="12"
                              class="input-field pb-0"
                            >
                              <label class=""
                                >{{ field.attr }}

                                <span
                                  v-if="field.isMandatory == 'true'"
                                  style="color: #e95e5e"
                                >
                                  *
                                </span>
                              </label>
                              <v-text-field
                                v-model="templateValues[index].value"
                                class="mt-2"
                                :type="field.type"
                                flat
                                solo
                                @input="clearError(index)"
                                :error="templateValues[index].error"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row v-if="tables.length > 0">
                            <v-col cols="12" class="">
                              <p class="mb-0 strong_text">
                                {{ $t('createCertModal.back') }}
                              </p>
                            </v-col>
                          </v-row>
                          <v-row
                            v-for="(table, indexT) in tables"
                            :key="table._id"
                          >
                            <v-col
                              v-for="(field, index) in table.headers"
                              :key="field._id"
                              :cols="
                                field.value == 'tecnología' ||
                                field.value == 'tecnologia' ||
                                field.value == 'technology'
                                  ? 12
                                  : 6
                              "
                              class="input-field pb-0 pr-2"
                            >
                              <label class="">
                                {{ field.text }}
                                <span style="color: #e95e5e"> * </span>
                              </label>
                              <v-text-field
                                v-model="tableLine[indexT][index].value"
                                class="mt-2"
                                :type="field.type"
                                flat
                                solo
                                @input="clearErrorTable(indexT, index)"
                                :error="tableLine[indexT][index].error"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="input-field pb-0">
                              <v-btn
                                class="back mr-4"
                                @click="addTableLine(indexT)"
                              >
                                <icon-add class="mt-0 mr-2" />
                                {{ $t('button.add') }}</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-stepper-content>

                      <v-stepper-content step="1" class="pa-0">
                        <v-container class="">
                          <v-row>
                            <v-col cols="12" class="input-field pb-0">
                              <label class=""> {{ $t('issue.wallet') }}</label>
                              <v-radio-group v-model="hasWallet" row>
                                <v-radio
                                  color="#009fb1"
                                  :label="$t('issue.radio[0]')"
                                  :value="1"
                                ></v-radio>
                                <v-radio
                                  color="#009fb1"
                                  :label="$t('issue.radio[1]')"
                                  :value="0"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>

                            <v-col
                              cols="12"
                              v-show="hasWallet == 1"
                              class="input-field pb-0"
                              style="padding-right: 10px"
                            >
                              <label class="">
                                {{ $t('issue.walletField') }}</label
                              >
                              <v-text-field
                                v-model="walletAddress"
                                class="mt-2"
                                flat
                                solo
                                :error="errorMail"
                              ></v-text-field>
                            </v-col>

                            <v-col
                              cols="12"
                              v-show="hasWallet == 0"
                              class="input-field pb-0"
                              style="padding-right: 10px"
                            >
                              <label class="">
                                {{ $t('issue.emailField') }}</label
                              >
                              <v-text-field
                                v-model="email"
                                class="mt-2"
                                flat
                                persistent-hint
                                :hint="$t('issue.emailHint')"
                                :error="errorMail"
                                solo
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-col>
                <v-col cols="6" class="pt-11">
                  <v-row>
                    <v-col cols="12" class="static_image" style="height: 260px">
                      <CustomCard
                        :frontTemplate="frontTemplate"
                        :backTemplate="backTemplate"
                        :tableValues="tableValues"
                        :templateValues="templateValues"
                        :caName="caName"
                        :credentialName="template.name"
                        :urlPhoto="photoURL"
                        :editable="editable"
                        :frontend_props="template.frontend_props"
                        @removeItem="removeItem"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <v-row class="justify-end" no-gutters>
              <v-btn class="back mr-4" v-if="step == 1" @click="back()">{{
                $t('button.back')
              }}</v-btn>
              <v-btn
                class="next"
                :class="confirmed ? 'confirm' : ''"
                :disabled="sending || (hasWallet == -1 && step == 1)"
                @click="nextStep()"
                >{{ buttonText }}</v-btn
              >
            </v-row>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import IconAdd from '../assets/icon-add.vue';
import IconClose from '../assets/icon-close.vue';

import CustomCard from '../components/CustomCard';
import { ISSUE_USER } from '../store/actions';

const KEYVAL = 'keyval';
const TABLE = 'table';
const LOGOS = 'logos';
const SIGS = 'sigs';

export default {
  name: 'IssueCertificate',
  components: {
    IconClose,
    CustomCard,
    IconAdd,
  },
  props: {
    template: {
      required: true,
    },
    caName: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(['photoURL', 'adminEmail']),
  },
  watch: {
    hasWallet(value) {
      console.log(value);
      if (value == 1) {
        this.email = null;
      } else if (value == 0) {
        this.walletAddress = null;
      }
    },
  },
  created: function () {
    this.$log.debug('IssueCertificate: ', this.template);

    console.log(this.tableLine);
    this.currentColor = this.template.frontend_props
      ? this.template.frontend_props.color
      : null;
    this.template.templateItens.forEach((e) => {
      if (e.attrFormat == KEYVAL) {
        this.frontTemplate.push({
          attr: e.attr,
          value: null,
          error: false,
          type: e.type,
          temp_item_id: e._id,
          isPublic: e.isPublic,
          isMandatory: e.isMandatory,
          order: e.order,
        });
        this.templateValues.push({
          attr: e.attr,
          value: null,
          error: false,
          type: e.type,
          temp_item_id: e._id,
          isPublic: e.isPublic,
          isMandatory: e.isMandatory,
        });
      } else if (e.attrFormat == TABLE) {
        this.tables.push({
          headers: e.table_headers,
          values: e.table_attr,
          item_id: e._id,
        });
        this.backTemplate = { headers: e.table_headers, values: e.table_attr };
      } else if (e.attrFormat == LOGOS) {
        console.log(e);
        this.logos = e.logos;
      } else if (e.attrFormat == SIGS) {
        console.log(e);
        this.sigs = e.sigs;
      }
    });

    this.frontTemplate.sort(this.compare);

    this.tables.forEach((t, i) => {
      this.tableLine.push([]);
      console.log(t);

      t.headers.forEach((e) => {
        console.log(e);

        let el = {
          attr: e.text,
          input: e.value,
          value: null,
          error: false,
        };
        el[e.value] = null;
        console.log(el);

        this.tableLine[i].push(el);
      });
    });
    console.log(this.templateValues);

    console.log(this.tableLine);
  },
  methods: {
    compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const valA = a.order;
      const valB = b.order;

      let comparison = 0;
      if (valA > valB) {
        comparison = 1;
      } else if (valA < valB) {
        comparison = -1;
      }
      return comparison;
    },
    isCustomTemplate() {
      return (
        this.adminEmail &&
        this.customTemplate[this.getEmail(this.adminEmail).domain]
      );
    },
    removeItem(index) {
      this.tableValues.splice(index, 1);
    },
    addTableLine(indexT) {
      console.log(this.tableLine[indexT]);
      let el = {};
      let error = false;
      this.tableLine[indexT].forEach((e, index) => {
        if (e.value) {
          this.tableLine[indexT][index].error = false;
          el[e.input] = e.value;
          e.value = null;
        } else {
          this.tableLine[indexT][index].error = true;
          error = true;
        }
      });
      if (!error) this.tableValues.push(el);
    },
    clearErrorTable(indexT, indexL) {
      this.tableLine[indexT][indexL].error = false;
    },
    clearError(index) {
      if (this.templateValues[index].value) {
        this.templateValues[index].error = false;
      } else {
        this.templateValues[index].error = true;
      }
    },
    checkForm() {
      let error = false;
      this.templateValues.forEach((e) => {
        if ((e.isMandatory == 'true' || e.isMandatory == true) && !e.value) {
          e.error = true;
          error = true;
        } else {
          e.error = false;
        }
      });
      if (this.tables && this.tables.length > 0 && this.tableValues.length == 0)
        error = true;

      return error;
    },
    createData() {
      this.data = this.templateValues.map((e) => {
        return {
          value: e.value,
          temp_item_id: e.temp_item_id,
          isPublic: e.isPublic,
        };
      });
      if (
        this.tables &&
        this.tables.length > 0 &&
        this.tableValues.length > 0
      ) {
        this.data.push({
          values: this.tableValues,
          temp_item_id: this.tables[0].item_id,
          isPublic: true,
        });
      }
      console.log(this.data);
    },
    close() {
      this.$emit('close');
    },
    back() {
      this.step -= 1;
      this.buttonText = this.$t('button.next');
      this.email = null;
      this.hasWallet = -1;
      this.confirmed = false;
      this.editable = true;
    },
    nextStep() {
      switch (this.step) {
        case 0:
          console.log('Check form');
          if (!this.checkForm()) {
            this.confirmed = true; // default: false
            this.createData();
            this.step += 1;
            this.buttonText = this.$t('button.issue');
            console.log(this.buttonText);
            this.editable = false;
          }
          break;
        case 1:
          console.log('Emit cert');
          this.errorMail = false;
          if (this.email || this.walletAddress) {
            if (!this.confirmed) {
              this.buttonText = this.$t('button.confirm');
              this.confirmed = true;
            } else {
              this.sending = true;
              this.$store
                .dispatch(ISSUE_USER, {
                  tid: this.template.tid,
                  email: this.email,
                  data: this.data,
                })
                .then(() => {
                  this.$emit('success', this.email ? 'email' : 'wallet');
                })
                .catch((err) => {
                  console.error(err);
                  this.sending = false;
                });
            }
          } else {
            this.errorMail = true;
          }
          // }
          break;

        default:
          break;
      }
    },
  },
  data() {
    return {
      customTemplate: { 'wallid.io': true },
      step: 0,
      currentColor: null,
      errorMail: false,
      editable: true,
      confirmed: false,
      backTemplate: null,
      frontTemplate: [],
      templateValues: [],
      tableValues: [],
      tableLine: [],
      tables: [],
      logos: [],
      sigs: [],
      hasWallet: -1,
      email: null,
      sending: false,
      walletAddress: null,
      buttonText: this.$t('button.next'),
    };
  },
};
</script>

<style lang="scss">
.modal-wallid {
  .confirm.v-btn {
    background-color: #00808e !important ;
  }
  .v-btn.back {
    padding: 0 12px;
  }
  .modal-body.scroll {
    max-height: 420px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .static_image {
    min-width: 380px;
    .id-card {
      position: absolute;
    }
  }
  .input-field {
    .v-input--radio-group {
      .v-input__slot {
        border: none !important;
      }
    }
    .v-input__control {
      min-height: unset;
      .v-input__slot {
        margin-bottom: 4px;
        height: 44px;
        border-radius: 3px;
        border: solid 1px var(--light-grey-blue);
      }
      .v-text-field__details {
        margin-bottom: 4px;
      }
    }
  }
}
</style>
