<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal-wallid success_modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header">
            <v-spacer />
            <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
            >
              <IconClose />
            </button>
          </slot>
        </header>

        <div class="modal-body text-center px-14 pb-9">
          <slot name="body">
            <IconSuccess />
            <h2 class="subtitle_header mx-auto pa-4">
              {{ $t('successModal.' + message + '.title') }}
            </h2>
            <p class="subtitle_text px-16">
              {{ $t('successModal.' + message + '.text', { designType }) }}
            </p>
          </slot>
        </div>

        <!--  <div class="modal-footer">
          <slot name="footer">
          <v-row class="justify-end" no-gutters>
              <v-btn class="next" :disabled="isDisabled" @click="nextStep()">
                {{ textButtonNext }}
              </v-btn> 
            </v-row>
          </slot>
        </div>-->
      </div>
    </div>
  </transition>
</template>

<script>
import IconClose from '../assets/icon-close.vue';
import IconSuccess from '../assets/icon-sucessfully.vue';

export default {
  name: 'IssueCertificate',
  components: {
    IconClose,
    IconSuccess,
  },
  created: function () {},
  props: {
    designType: {},
    message: {
      required: true,
    },
  },
  watch: {},
  computed: {},
  methods: {
    close() {
      this.$emit('close');
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.modal-wallid.success_modal {
  position: relative;
  .v-stepper {
    box-shadow: none;
  }
  .modal-header {
    padding-bottom: 0 !important;
    border-bottom: none !important;
  }
  .modal-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  .container.scroll {
    max-height: 420px;
    overflow: auto;
  }
  .static_image {
    .v-image {
      position: absolute;
    }
  }
  .input-field {
    .v-input__control {
      min-height: unset;
      .v-input__slot {
        margin-bottom: 4px;
        height: 44px;
        border-radius: 3px;
        border: solid 1px var(--light-grey-blue);
      }
      .v-text-field__details {
        margin-bottom: 4px;
      }
    }
  }
}
</style>
